/*=========================================================================================
  File Name: sidebarItems.js
  Description: Sidebar Items list. Add / Remove menu items from here.
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


export default [
  {
    url: "/",
    name: "Dashboard",
    slug: "dashboard",
    icon: "BarChart2Icon",
  },
  {
    url: "/administradores",
    name: "Administradores",
    slug: "administradores",
    icon: "UserIcon",
  },
  {
    url: "/projetos",
    name: "Projetos",
    slug: "projetos",
    icon: "CodeIcon",
  },
  {
    url: "/sentry",
    name: "Alertas Sentry",
    slug: "alertas-sentry",
    icon: "AlertTriangleIcon",
  },
]
